<template>
	<div>
		    <div class="enterprise">
		<div style="background-color: #fff;">
		<el-row>
			<el-col :span="4">
				<el-menu
				      default-active="5"
							@select="check"
				      class="el-menu-vertical-demo"
							@click="check">
				      <el-menu-item index="1">
				        <span slot="title">首页</span>
								</el-menu-item>
				      <el-menu-item index="2">
				        <span slot="title">账号中心</span>
				      </el-menu-item>
				      <el-menu-item index="3">
				        <span slot="title">套餐购买</span>
				      </el-menu-item>
				      <el-submenu index="4">
				        <template slot="title">
				          <span>企业招聘</span>
				        </template>
				        <el-menu-item-group>
				          <el-menu-item index="4-1">发布招聘信息</el-menu-item>
				          <el-menu-item index="4-2">管理招聘信息</el-menu-item>
				          <el-menu-item index="4-3">收到的简历投递</el-menu-item>
				          <el-menu-item index="4-4">已收藏简历</el-menu-item>
				        </el-menu-item-group>
				      </el-submenu>
				      <el-menu-item index="5">
				        <!-- <template> -->
				          <span slot="title">企业培训订单</span>
				        <!-- </template>
				        <el-menu-item-group>
				          <el-menu-item index="5-1">培训信息</el-menu-item>
				          <el-menu-item index="5-2">培训</el-menu-item> -->
				          <!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
				        <!-- </el-menu-item-group> -->
				      </el-menu-item>
							<!-- <el-menu-item index="6">
				          <span slot="title">企业办证订单</span>
				      </el-menu-item> -->
							<!-- <el-submenu index="6">
							  <template slot="title">
							    <span>企业办证</span>
							  </template>
							  <el-menu-item-group>
							    <el-menu-item index="6-1">办证信息</el-menu-item>
							    <el-menu-item index="6-2">办证订单</el-menu-item>
							  </el-menu-item-group>
							</el-submenu> -->
				      <el-menu-item index="7">
				        <span slot="title">企业公告</span>
				      </el-menu-item>
				      <el-menu-item index="8">
				        <span slot="title">中介担保</span>
				      </el-menu-item>
							<!-- <el-menu-item index="9">
							  <span slot="title">企业资料</span>
							</el-menu-item> -->
							<el-menu-item index="10">
							  <span slot="title">我的订阅</span>
							</el-menu-item>
				    </el-menu>
			</el-col>
			<el-col :span="20">
				<div class="menuContent">
          
		<div class="trainInfo">
			<div class="trainInfo-title">培训订单</div>
		</div>
		<!-- <el-form ref="form" :model="form" label-width="5.8vw">
			<el-form-item label="创建时间">
				<el-col :span="11">
					<el-date-picker
						v-model="formInline.createTime"
						type="datetimerange"
						align="right"
						unlink-panels
						clearable
						value-format="yyyy-MM-dd hh:mm:ss"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="dateChange"
					></el-date-picker>
				</el-col>
			</el-form-item>
			<el-form-item label="订单状态">
				<el-button type="text" @click="checkStatus('')">全部</el-button>
				<el-button type="text" @click="checkStatus(1)">待支付</el-button>
				<el-button type="text" @click="checkStatus(2)">逾期支付</el-button>
				<el-button type="text" @click="checkStatus(3)">已支付(全款)</el-button>
				<el-button type="text" @click="checkStatus(4)">申请退款</el-button>
				<el-button type="text" @click="checkStatus(5)">已退款</el-button>
				<el-button type="text" @click="checkStatus(6)">完结</el-button>
				<el-button type="text" @click="checkStatus(7)">部分支付</el-button>
			</el-form-item>
			<el-form-item label="订单搜索" class="enterprise-search">
				<el-input v-model="search" class="input-with-select" @input="clearInput">
					<el-select v-model="select" slot="prepend" placeholder="请选择">
						<el-option label="课程标题" value="trainingName"></el-option>
						<el-option label="订单号" value="orderNum"></el-option> -->
						<!-- <el-option label="用户电话" value="3"></el-option> -->
					<!-- </el-select>
				</el-input>
				<el-button @click="searchFrom" ref="searchBtn" :type="search ? 'primary' : 'info'">搜索</el-button>
			</el-form-item>
		</el-form> -->
		<el-table
			border
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{
				'background-color': 'rgba(32, 137, 255, 0.07)',
				color: '#333',
				'font-size': '0.833vw',
				'font-weight': '400'
			}"
		>
			<el-table-column prop="id" label="订单ID" width="80"></el-table-column>
			<el-table-column prop="orderNum" label="订单号"></el-table-column>
			<el-table-column prop="training.name" label="课程标题"></el-table-column>
			<el-table-column prop="pay" label="支付费用" width="100"></el-table-column>
			<el-table-column prop="name" label="用户" width="80"></el-table-column>
			<el-table-column prop="createTime" label="创建时间">
				<template slot-scope="scope">
					{{$dy_tool.formatDate(scope.row.createTime) }}
				</template>
			</el-table-column>
			<el-table-column prop="status" label="状态" width="80">
				<template slot-scope="scope">
					<div v-if="scope.row.status == 1">待支付</div>
					<div v-if="scope.row.status == 2">逾期支付</div>
					<div v-if="scope.row.status == 3">已支付(全款)</div>
					<div v-if="scope.row.status == 4">申请退款</div>
					<div v-if="scope.row.status == 5">已退款</div>
					<div v-if="scope.row.status == 6">完结</div>
					<div v-if="scope.row.status == 7">部分支付</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="60">
				<template slot-scope="scope">
					<el-button type="text" @click="jump('ec_training_order_details?id='+scope.row.id)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="tableBottom">
			<div style="color:#F95022;font-size:16px">总额: ￥{{totalFee}}</div>
			<div style="text-align:right;">
			<el-pagination style="margin-top:20px;text-align:right;"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
				</div>
		</div>
		</div>
		<el-dialog
				title="提示"
				:visible.sync="showSub"
				width="30%">
				<span v-if="subform.subscribe" style="margin-right:20px">是否关闭订阅</span>
				<span v-else style="margin-right:20px">是否开启订阅</span>
				<el-radio v-model="subscription" :label="true">是</el-radio>
				<el-radio v-model="subscription" :label="false">否</el-radio>
				<span slot="footer" class="dialog-footer">
					<el-button @click="showSub = false">取 消</el-button>
					<el-button type="primary" @click="editSub">确 定</el-button>
				</span>
			</el-dialog>
			</el-col>
						
		</el-row>
		</div>
  </div>
      
	</div>
</template>

<script>

import adver from '@/pages/advertisement.vue';
import BaseMixin from '@/mixins/BaseMixin.vue';
export default {
	mixins: [BaseMixin],
	data() {
		return {
			checkIndex: '5',
			form: {
				date: '',
				employJob: '选项1',
				search: ''
			},
			options: [
				{
					value: '选项1',
					label: '所有职务'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [
			],
			search: '',
			select: 'trainingName',
			currentPage: 1,
			total: 1,
			formInline: {
        companyName: "", // 搜索字段
        orderNum: "", 
        status: 0,
        page: 0, // 当前页
        size: 10,
      },
			totalFee: 0,
			showSub: false,
			subscription: true,
			subform: {}
		};
	},
	components: {
		adver
	},
	methods: {
		initData(formInline) {
			this.subform = {
				id: this.vuex_user.id,
				nickName: this.vuex_user.nickName,
				avatar: this.vuex_user.avatar,
				gender: this.vuex_user.gender,
				openId: this.vuex_user.openId,
				wxOpenid: this.vuex_user.wxOpenid,
				phone: this.vuex_user.phone,
				password: this.vuex_user.password,
				name: this.vuex_user.name,
				simpleName: this.vuex_user.simpleName,
				province: this.vuex_user.province,
				city: this.vuex_user.city,
				district: this.vuex_user.district,
				address: this.vuex_user.address,
				postalCode: this.vuex_user.postalCode,
				website: this.vuex_user.website,
				intro: this.vuex_user.intro,
				subscribe: this.vuex_user.subscribe,
				isPass: this.vuex_user.isPass,
				openAgency: this.vuex_user.openAgency,
				createTime: this.vuex_user.createTime,
				autid: this.vuex_user.autid
			}
			this.subscription = this.subform.subscribe
			this.totalFee = 0
			this.$u.api.orderList(formInline).then(res =>{
				res.data.content.forEach(e => {
					this.totalFee += e.pay
				});
				this.tableData = res.data.content
				this.total = res.data.totalElements
				
			})
		},
		onSubmit() {
			console.log('submit!');
		},
		handleSizeChange(val) {
      this.formInline.size = val
      this.initData(this.formInline)
    },
    handleCurrentChange(val) {
      this.formInline.page = val-1
      this.initData(this.formInline)
    },

		handleEdit(index, row) {
		},
		dateChange(e) {
			
			this.initData(this.formInline)
		},
		checkStatus(e) {
			this.formInline.status = e
			this.initData(this.formInline)
			
		},
		clearInput(e) {
			if(e == '') {
				this.formInline = {}
				this.formInline.page = 0
				this.formInline.size = 10
				this.initData(this.formInline)
			}
			// 
		},
		searchFrom() {
			if(this.select == 'orderNum') {
				this.formInline.orderNum = this.search
				this.initData(this.formInline)
			}else if (this.select == 'trainingName') {
				this.formInline.trainingName = this.search
				this.initData(this.formInline)
			}
		},
		editSub() {
			if(this.subscription) {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast({title:'订阅开启成功',icon:'none'})
					this.subform.subscribe = true
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					
				})
			}else {
				this.$u.api.updateSubscribe(this.subscription+'').then(res => {
					this.uni.showToast({title:'订阅关闭成功',icon:'none'})
					this.subform.subscribe = false
					this.$u.vuex('vuex_user', this.subform)
					this.showSub = false
					
				})
			}
			
		},
		check(index) {
						this.checkIndex = index
						if(index == '1') {
							this.jump('enterprise_index')
						}else if(index == '2') {
							this.jump('ec_account_manage')
						}
						else if(index == '3') {
							this.jump('ec_buy_package')
						}
						else if(index == '4-1') {
							this.jump('post_recruitment_info')
						}
						else if(index == '4-2') {
							this.jump('manage_recruitment_info')
						}
						else if(index == '4-3') {
							this.jump('resume_delivery_received')
						}
						else if(index == '4-4') {
							this.jump('collected_resume')
						}
						else if(index == '5-1') {
							this.jump('ec_training_info')
						}
						else if(index == '5') {
							this.jump('ec_training_order')
						}
						else if(index == '5-3') {
							this.jump('training_school')
						}
						else if(index == '7') {
							this.jump('corporate_announcements')
						}
						else if(index == '8') {
							// this.$u.api.companyLoginInfo().then(res => {
							// 	if(res.data.openAgency) {
								if(this.vuex_user.openAgency){
									this.jump('ec_mediation_guarantee')
								}else {
									this.uni.showToast({title:'您未开通中介担保',icon:'none'})
								}
							// })
						}
						else if(index == '9') {
							this.jump('published_data')
						}
						else if(index == '10') {
							this.showSub = true
						}
					},
	}
};
</script>
<style>
page {
	background: none;
}
.enterprise-search >>> .el-form-item__content {
	display: flex;
}
.el-input >>> .el-input-group__prepend {
	width: 70px;
	background-color: #fff;
}
.el-submenu .el-menu-item{
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.enterprise {
		margin: 0  auto; width:68.5vw; /* 边距 */
		padding-top: 30px;box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
		background: #fff;
		.menuContent {
			background: #fff;
			padding: 0.5vw 2.083vw 1.5625vw;
		}
	}
.trainInfo {
	// width: 100%;
	// border-bottom: 1px solid #e5e5e5;
	padding-bottom: 20px;
	.trainInfo-title {
		font-size: 1.0416vw;
	}
	button {
		margin: 0;
		width: 100px;
		background-color: #2089ff;
		font-size: 14px;
		color: #ffffff;
	}
}

.el-form {
	margin-top: 2.604vw;
	.el-input {
		width: 15.625vw;
		margin-right: 0.52vw;
	}
}
.el-table {
	margin-top: 1.6vw;
}
.tableBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.833vw 0 6.25vw;
}
</style>
