import { render, staticRenderFns } from "./delivery_record.vue?vue&type=template&id=4ffd5a5e&scoped=true&"
import script from "./delivery_record.vue?vue&type=script&lang=js&"
export * from "./delivery_record.vue?vue&type=script&lang=js&"
import style0 from "./delivery_record.vue?vue&type=style&index=0&id=4ffd5a5e&prod&lang=css&"
import style1 from "./delivery_record.vue?vue&type=style&index=1&id=4ffd5a5e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffd5a5e",
  null
  
)

export default component.exports