import { render, staticRenderFns } from "./buy_package.vue?vue&type=template&id=78c4e020&scoped=true&"
import script from "./buy_package.vue?vue&type=script&lang=js&"
export * from "./buy_package.vue?vue&type=script&lang=js&"
import style0 from "./buy_package.vue?vue&type=style&index=0&id=78c4e020&prod&lang=css&"
import style1 from "./buy_package.vue?vue&type=style&index=1&id=78c4e020&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c4e020",
  null
  
)

export default component.exports