// 导入axios
import axios from 'axios'
// import qs from 'qs'
//导入QS
// import Vue from 'Vuex'
// 使用element-ui Message用以消息提醒
import {
	Message,
	MessageBox
} from 'element-ui';
import {
	Toast
} from 'vant';
// 创建新的axios实例
const service = axios.create({
	// 公共接口(暂未配置，预计写死)
	baseURL: "https://ship.hy151.cn/front",
	// 超时时间 单位是ms
	timeout: 20 * 1000,
})


const config = require('@/config/config')
const hrefHost = process.env.NODE_ENV === "development" ? config.devHref : config.prodHref
const getVisit = () => {
	let pwd = ''; // 随机18位字符
	let type = 0; //  默认0是游客 1是用户 2是公司 
	let uid = new Date().getTime(); // 用户id，游客为唯一设备id字符串
	let $chars =
		'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	let maxPos = $chars.length
	for (let i = 0; i < 18; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
	}
	if (localStorage.getItem('member_token')) {
		type = 1
		uid = JSON.parse(localStorage.getItem('vuex_user')).id
	}
	if (localStorage.getItem('vuex_token')) {
		type = 2
		uid = JSON.parse(localStorage.getItem('vuex_user')).id
	}


	return pwd + '_PC_' + uid + '_' + type;
}

// 请求拦截器
service.interceptors.request.use(config => {
	// console.log('执行请求拦截', this);
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求添加
	// config.data = qs.stringify(config.data); //json数据转化
	// config.headers = {
	//   // 'Content-Type': 'application/x-www-form-urlencoded' //配置请求头
	//   Token: vuex_token
	// }
	//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	//判断localStorage是否存在token
	// if (localStorage.getItem('token')) {
	//   //携带token到axios参数
	//   config.headers.Authorization = '固定携带的头部';
	//   config.params = {
	//     //固定携带参数
	//   }
	// }
	// const token = getCookie('名称'); //这里取token之前，需要先拿到token,存一下
	// if (token) {
	//   config.params = {
	//     'token': token
	//   } //如果要求携带在参数中
	//   config.headers.token = token; //如果要求携带在请求头中
	// }
	let Token = null
	if (localStorage.getItem('vuex_token')) {
		Token = 'Company ' + localStorage.getItem('vuex_token')
	}
	if (localStorage.getItem('member_token')) {
		Token = 'Member ' + localStorage.getItem('member_token')
	}
	// console.log('当前token',Token);        
	if (Token && Token.indexOf("\"") !== -1) {
		// 存在引号
		Token = Token.replaceAll("\"", "");
	}
	config.headers.Token = Token; //如果要求携带在请求头中
	config.headers.visit = getVisit();
	config.headers['content-type'] = 'application/json;charset=UTF-8'
	return config
}, error => {
	Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(res => {
	// console.log('响应拦截', res);
	if (res.status === 200) {
		// 未设置状态码则默认成功状态
		const code = res.data.status || 200;
		if (code === 401) {
			MessageBox.alert('您还未登录或登录已过期,请重新登录', '登录提示', {
				confirmButtonText: '确定',
				callback: res => {
					// Message.success({
					//   message: '退出登录'
					// })
					console.log('退出登录');
					window.location.href = hrefHost + 'login.html'
				}
			})
			// window.location.href = hrefHost + 'login.html'
			return Promise.reject(new Error('您还未登录或登录已过期,请重新登录'))
		} else if (code !== 200 && code !== 1) {
			console.log(res)
			// 调试时不进行提示
			if (window.location.href.indexOf('dist') === -1) {
				Toast(res.data.msg || "")
			}
			console.log('请求code')
			// this.uni.showToast(res.data.data || res.data.msg);
			return Promise.reject(new Error(res.data.msg))
		} else {
			console.log('请求正常')
			return res.data
			// console.log('请求正常', res.data);
		}
	} else {
		// 不返回原始数据的情况下，服务器状态码不为200，modal弹框提示
		if (res.errMsg) {
			// 自己知道就行了，别给用户看了
			if (process.env.NODE_ENV === 'development') {
				// this.uni.showToast('服务器繁忙，请稍后再试');
				Message.error({
					message: '服务器繁忙，请稍后再试'
				})
			}
		}
		return false
	}
	// return res.data
}, error => {
	console.log('请求异常');
	/***** 接收到异常响应的处理开始 *****/
	if (error && error.response) {
		// 根据响应码具体处理
		switch (error.response.status) {
			case 400:
				error.message = '错误请求'
				break;
			case 401:
				error.message = '未授权，请重新登录'
				break;
			case 403:
				error.message = '拒绝访问'
				break;
			case 404:
				error.message = '请求错误,未找到该资源'
				window.location.href = "/NotFound"
				break;
			case 405:
				error.message = '请求方法未允许'
				break;
			case 408:
				error.message = '请求超时'
				break;
			case 500:
				error.message = '服务器端出错'
				break;
			case 501:
				error.message = '网络未实现'
				break;
			case 502:
				error.message = '网络错误'
				break;
			case 503:
				error.message = '服务不可用'
				break;
			case 504:
				error.message = '网络超时'
				break;
			case 505:
				error.message = 'http版本不支持该请求'
				break;
			default:
				error.message = `连接错误${error.response.status}`
		}
	} else {
		// 超时处理
		if (JSON.stringify(error).includes('timeout')) {
			Message.error('服务器响应超时，请刷新当前页')
		}
		error.message = '连接服务器失败'
	}
	Message.error(error.message)
	/***** 处理结束 *****/
	return Promise.reject(error.response)
})
//暴露文件
export default service
