<template>
	<div class="personal">
		<div style="background-color: #fff;">
			<el-row>
				<el-col :span="4">
					<el-menu
					      default-active="7"
								@select="check"
					      class="el-menu-vertical-demo"
								@click="check">
					      <el-menu-item index="1">
					        <span slot="title">账号管理</span>
					      </el-menu-item>
					      <el-menu-item index="2">
					        <span slot="title">套餐购买</span>
					      </el-menu-item>
					      <el-submenu index="3">
					        <template slot="title">
					          <span>我的简历</span>
					        </template>
					        <el-menu-item-group>
					          <el-menu-item index="3-1">简历管理</el-menu-item>
					          <el-menu-item index="3-2">投递记录</el-menu-item>
					          <el-menu-item index="3-3">收藏企业</el-menu-item>
					        </el-menu-item-group>
					      </el-submenu>
					      <el-menu-item index="4">
					        <span slot="title">中介担保</span>
					      </el-menu-item>
					      <el-menu-item index="5">
					        <span slot="title">培训订单</span>
					      </el-menu-item>
					      <el-menu-item index="6">
					        <span slot="title">证件办理</span>
					      </el-menu-item>
								<el-menu-item index="10">
							  <span slot="title">我的订阅</span>
							</el-menu-item>
					    </el-menu>
				</el-col>
				<el-col :span="20">
					<div class="menuContent">
		<div class="subscription">
		<div class="subscription-title">我的订阅</div>
		<el-form ref="form" :model="form" label-width="4.167vw">
			<el-form-item label="收到时间">
				<el-col :span="11">
					<el-date-picker
						v-model="form.date"
						type="daterange"
						align="right"
						unlink-panels
						clearable
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions"
					></el-date-picker>
				</el-col>
			</el-form-item>
			<el-form-item label="应聘职务">
				<el-select v-model="form.employJob"><el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select>
			</el-form-item>
			<el-form-item label="企业搜索" class="enterprise-search">
				<el-input v-model="form.search" placeholder="请输入招聘企业"></el-input>
				<el-button ref="searchBtn" :type="form.search?'primary':'info'">搜索</el-button>
			</el-form-item>
		</el-form>
		<el-table
			border
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{
				'background-color': 'rgba(32, 137, 255, 0.07)',
				color: '#333',
				'font-size': '0.833vw',
				'font-weight': '400'
			}"
		>
			<el-table-column prop="post" label="职务" width="80"></el-table-column>
			<el-table-column prop="certificate" label="证书" width="80"></el-table-column>
			<el-table-column prop="salary" label="工资" width="80"></el-table-column>
			<el-table-column prop="shipType" label="船型"></el-table-column>
			<el-table-column prop="shipRoute" label="航线"></el-table-column>
			<el-table-column prop="tonnage" label="吨位"></el-table-column>
			<el-table-column prop="recruiters" label="招聘企业"></el-table-column>
			<el-table-column prop="date" label="日期"></el-table-column>
			<el-table-column label="操作" width="80">
				<template slot-scope="scope">
					<el-button type="text" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding:0.833vw 0 6.25vw;text-align:right;"><el-pagination layout="prev, pager, next, jumper" :total="100"></el-pagination></div>
		</div>
		</div>
			</el-col>
						
		</el-row>
		</div>
	</div>
</template>

<script>

import adver from '@/pages/advertisement.vue';
import BaseMixin from '@/mixins/BaseMixin.vue';
export default {
	mixins: [BaseMixin],
	data() {
		return {
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			form: {
				date: '',
				employJob: '选项1',
				search: '',
			},
			options: [
				{
					value: '选项1',
					label: '所有职务'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [
				{
					post: '机工',
					certificate: '丙二',
					salary: '面议',
					shipType: '散杂货',
					shipRoute: '国内南北线',
					tonnage: '500-3000',
					recruiters: '广安洋船舶服务',
					date: '2021-06-20'
				},
				{
					post: '机工',
					certificate: '丙二',
					salary: '面议',
					shipType: '散杂货',
					shipRoute: '国内南北线',
					tonnage: '500-3000',
					recruiters: '广安洋船舶服务',
					date: '2021-06-20'
				},{
					post: '机工',
					certificate: '丙二',
					salary: '面议',
					shipType: '散杂货',
					shipRoute: '国内南北线',
					tonnage: '500-3000',
					recruiters: '广安洋船舶服务',
					date: '2021-06-20'
				},{
					post: '机工',
					certificate: '丙二',
					salary: '面议',
					shipType: '散杂货',
					shipRoute: '国内南北线',
					tonnage: '500-3000',
					recruiters: '广安洋船舶服务',
					date: '2021-06-20'
				},{
					post: '机工',
					certificate: '丙二',
					salary: '面议',
					shipType: '散杂货',
					shipRoute: '国内南北线',
					tonnage: '500-3000',
					recruiters: '广安洋船舶服务',
					date: '2021-06-20'
				},
			],
			checkIndex: '7',
		};
	},
	methods: {
		initData() {},
		onSubmit() {
			console.log('submit!');
		},
		
		handleEdit(index, row) {
			console.log(index, row);
		},
		check(index) {
						this.checkIndex = index
						if(index == '1') {
							this.jump('account_manage')
						}
						else if(index == '2') {
							this.jump('buy_package')
						}
						else if(index == '3-1') {
							this.jump('resume_manage')
						}
						else if(index == '3-2') {
							this.jump('delivery_record')
						}
						else if(index == '3-3') {
							this.jump('collection_position')
						}
						else if(index == '4') {
							this.jump('personal_guarantee')
						}
						else if(index == '5') {
							this.jump('training_order')
						}
						else if(index == '6') {
							this.jump('document_handling')
						}
						console.log(this.checkIndex)
					},
	}
};
</script>
<style>
page {
	/* background: #fff; */
	background:none;
}
.enterprise-search >>> .el-form-item__content {
			display: flex;
		}
</style>
<style lang="less" scoped>
	.personal {
			// margin: 10px 18.75vw 6.25vw;
			margin: 0  auto; width:68.5vw; /* 边距 */
			padding-top: 30px;
			background: #fff;
			.menuContent {
				background: #fff;
				// padding: 1.5625vw 2.083vw;
				padding: 0.5vw 2.083vw 1.5625vw;
.subscription {
	.subscription-title {
		font-size: 1.0416vw;
	}
	.el-form {
		margin-top: 2.604vw;
		.el-input {
			width: 15.625vw;
			margin-right: 0.52vw;
		}
	}
	.el-table {
		margin-top: 2.083vw;
	}
}
}
	}
</style>
