<template>
  <div class="personal">
		<div style="background-color: #fff;">
		<el-row>
			<el-col :span="4">
				<el-menu
				      default-active="3-2"
							@select="check"
				      class="el-menu-vertical-demo"
							@click="check">
				      <el-menu-item index="1">
				        <span slot="title">账号管理</span>
				      </el-menu-item>
				      <el-menu-item index="2">
				        <span slot="title">套餐购买</span>
				      </el-menu-item>
				      <el-submenu index="3">
				        <template slot="title">
				          <span>我的简历</span>
				        </template>
				        <el-menu-item-group>
				          <el-menu-item index="3-1">简历管理</el-menu-item>
				          <el-menu-item index="3-2">投递记录</el-menu-item>
				          <el-menu-item index="3-3">收藏企业</el-menu-item>
				        </el-menu-item-group>
				      </el-submenu>
				      <el-menu-item index="4">
				        <span slot="title">中介担保</span>
				      </el-menu-item>
				      <el-menu-item index="5">
				        <span slot="title">培训订单</span>
				      </el-menu-item>
				      <el-menu-item index="6">
				        <span slot="title">证件办理</span>
				      </el-menu-item>
							<el-menu-item index="7">
				        <span slot="title">我的订阅</span>
				      </el-menu-item>
				    </el-menu>
			</el-col>
			<el-col :span="20">
				<div class="menuContent">
					<div class="deliveryRecode">
		<div class="delivery-title">简历投递记录</div>
		<el-table border :data="tableData" style="width: 100%" :header-cell-style="{
								'background-color': 'rgba(32, 137, 255, 0.07)',
								'color': '#333',
								'font-size': '0.833vw',
								'font-weight': '400'
							}">
			<el-table-column prop="recruit.company.name" label="公司名称"></el-table-column>
			<el-table-column prop="recruit.job" label="投递职务"></el-table-column>
			<el-table-column prop="createTime" label="投递日期"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
					<el-button size="mini" type="text" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
<el-pagination style="margin-top:20px;text-align:right;"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
		</div>
		</div>
          <el-dialog
            title="提示"
            :visible.sync="showSub"
            width="30%">
            <span v-if="userForm.subscribe" style="margin-right:20px">是否关闭订阅</span>
            <span v-else style="margin-right:20px">是否开启订阅</span>
            <el-radio v-model="subscription" :label="true">是</el-radio>
            <el-radio v-model="subscription" :label="false">否</el-radio>
            <span slot="footer" class="dialog-footer">
              <el-button @click="showSub = false">取 消</el-button>
              <el-button type="primary" @click="editSub">确 定</el-button>
            </span>
          </el-dialog>
			</el-col>
						
		</el-row>
		</div>
		  
  </div>
</template>

<script>
import adver from '@/pages/advertisement.vue';
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
  mixins: [BaseMixin],
  data() {
    return {
			tableData: [],
			checkIndex: '3-2',
			currentPage: 1,
			total: 1,
			formInline: {
        companyName: "", // 搜索字段
        orderNum: "", 
        status: 0,
        page: 0, // 当前页
        size: 10,
      },
      userForm: {
				nickName: 'sunshine',
				userPwd: '********',
				gender: '男',
				email: '2222222222@qq.com',
				select: '1'
			},
			showSub: false,
			subscription: true,
    }
  },
	components: {
		adver
	},
  methods: {
		initData(formInline) {
      this.userForm = {
    		phone: this.vuex_user.phone,
    		nickName: this.vuex_user.nickName,
    		gender: this.vuex_user.gender,
    		email: this.vuex_user.email,
				subscribe: this.vuex_user.subscribe
    	}
			this.$u.api.sendList(formInline).then(res => {
				res.data.content.forEach(e => {
					e.createTime = this.$dy_tool.formatDate(e.createTime,'yyyy-MM-dd')
				});
				this.tableData = res.data.content
				this.total = res.data.totalElements
				console.log(res)
			})
		},
		handleEdit(index, row) {
			this.jump('recruitment_details?id='+row.recruit.id)
			console.log(index, row);
		},
		handleDelete(index, row) {
			console.log(index, row);
		},
		editSub() {
			if(this.subscription) {
				this.userForm.subscribe = true
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅开启成功')
						this.showSub = false
					})
				})
			}else {
				this.userForm.subscribe = false
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅关闭成功')
						this.showSub = false
					})
				})
			}
			
		},
		handleSizeChange(val) {
      this.formInline.size = val
      this.initData(this.formInline)
    },
    handleCurrentChange(val) {
      this.formInline.page = val-1
      this.initData(this.formInline)
    },
		check(index) {
						this.checkIndex = index
						if(index == '1') {
							this.jump('account_manage')
						}
						else if(index == '2') {
							this.jump('buy_package')
						}
						else if(index == '3-1') {
							this.jump('resume_manage')
						}
						else if(index == '3-3') {
							this.jump('collection_position')
						}
						else if(index == '4') {
							this.jump('personal_guarantee')
						}
						else if(index == '5') {
							this.jump('training_order')
						}
						else if(index == '6') {
							this.jump('document_handling')
						}
						else if(index == '7') {
							this.showSub = true
						}
						console.log(this.checkIndex)
					},
  }
}
</script>
<style>
page{
  background:none;
}
.el-submenu .el-menu-item{
	min-width:100%;
}
</style>
<style lang="less" scoped>
	.personal {
			margin: 0  auto; width:68.5vw; /* 边距 */
			padding-top: 30px;
			background: #fff;
		box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
			.menuContent {
				background: #fff;
				padding: 0.5vw 2.083vw 1.5625vw;
	.deliveryRecode {
		.delivery-title {
			font-size: 1.0416vw;
		}
		.el-table {
			margin-top: 2.604vw;
		}
	}
	}
	}
</style>