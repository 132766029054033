<template>
	<div class="personal">
		<div style="background-color: #fff;">
			<el-row>
				<el-col :span="4">
					<el-menu default-active="4" @select="check" class="el-menu-vertical-demo" @click="check">
						<el-menu-item index="1">
							<span slot="title">账号管理</span>
						</el-menu-item>
						<el-menu-item index="2">
							<span slot="title">套餐购买</span>
						</el-menu-item>
						<el-submenu index="3">
							<template slot="title">
								<span>我的简历</span>
							</template>
							<el-menu-item-group>
								<el-menu-item index="3-1">简历管理</el-menu-item>
								<el-menu-item index="3-2">投递记录</el-menu-item>
								<el-menu-item index="3-3">收藏企业</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item index="4">
							<span slot="title">中介担保</span>
						</el-menu-item>
						<el-menu-item index="5">
							<span slot="title">培训订单</span>
						</el-menu-item>
						<el-menu-item index="6">
							<span slot="title">证件办理</span>
						</el-menu-item>
						<el-menu-item index="7">
							<span slot="title">我的订阅</span>
						</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="20">
					<div class="menuContent">
						<div class="personal-agent">
							<div class="agent-top">
								<div class="agent-title">中介担保</div>
								<div class="agent-btn" @click="jump('guarantee')">发起担保</div>
							</div>

							<!-- 
		<div class="search">
			<el-input placeholder="搜索" v-model="input2" suffix-icon="el-icon-search" class="input-with-select" @change="inputChange">
			    <el-select v-model="search" slot="prepend" placeholder="请选择" @change="searchChange">
			      <el-option label="公司名称" value="1"></el-option>
			      <el-option label="业务单号" value="2"></el-option>
			    </el-select>
			  </el-input>
		</div> -->
							<el-table border :data="tableData" style="width: 100%" :header-cell-style="{
								'background-color': 'rgba(32, 137, 255, 0.07)',
								'color': '#333',
								'font-size': '0.833vw',
								'font-weight': '400'
							}">
								<el-table-column prop="company.name" label="中介公司名称"></el-table-column>
								<el-table-column prop="orderNum" label="业务单号"></el-table-column>
								<el-table-column prop="status" label="业务状态">
									<template scope="scope">
										<p v-if="scope.row.status == -1">已取消</p>
										<p v-if="scope.row.status == 1">待支付</p>
										<p v-if="scope.row.status == 2">逾期支付</p>
										<p v-if="scope.row.status == 3">已支付</p>
										<p v-if="scope.row.status == 4">申请退款</p>
										<p v-if="scope.row.status == 5 && scope.row.stateFeedback == 0">已上船</p>
										<p v-if="scope.row.status == 5 && scope.row.stateFeedback == 1">已反馈已上船</p>
										<p v-if="scope.row.status == 5 && scope.row.stateFeedback == 2">已反馈未上船</p>
										<p v-if="scope.row.status == 6">未上船</p>
										<p v-if="scope.row.status == 7">完成</p>
										<p v-if="scope.row.status == 8">退款</p>
									</template>
								</el-table-column>
								<el-table-column prop="agencyFee" label="中介费" width="80"></el-table-column>
								<el-table-column prop="serviceFee" label="服务费" width="80"></el-table-column>
								<el-table-column prop="totalFee" label="总费用" width="80"></el-table-column>
								<el-table-column label="操作" width="140">
									<template slot-scope="scope">
										<el-button type="text" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
										<el-button v-if="scope.row.status == 1" type="text"
											@click="handleDelete(scope.$index, scope.row)">取消</el-button>
										<el-button v-if="scope.row.status == 5 && scope.row.stateFeedback == 0" type="text"
											@click="checkIfboat(scope.row.id, 1)">确认上船</el-button>
										<el-button v-if="scope.row.status == 5 && scope.row.stateFeedback == 0" type="text"
											@click="checkIfboat(scope.row.id, 2)">未上船</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination style="margin-top:20px;text-align:right;" :current-page="currentPage"
								:page-sizes="[10, 20, 50, 100]" :page-size="10" layout="prev, pager, next, jumper" :total="total"
								@size-change="handleSizeChange" @current-change="handleCurrentChange" />
						</div>
					</div>
					<el-dialog title="提示" :visible.sync="showSub" width="30%">
						<span v-if="userForm.subscribe" style="margin-right:20px">是否关闭订阅</span>
						<span v-else style="margin-right:20px">是否开启订阅</span>
						<el-radio v-model="subscription" :label="true">是</el-radio>
						<el-radio v-model="subscription" :label="false">否</el-radio>
						<span slot="footer" class="dialog-footer">
							<el-button @click="showSub = false">取 消</el-button>
							<el-button type="primary" @click="editSub">确 定</el-button>
						</span>
					</el-dialog>
				</el-col>

			</el-row>
		</div>

	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			currentPage: 0,
			total: 1,
			formInline: {
				companyName: "", // 搜索字段
				orderNum: "",
				page: 0, // 当前页
				size: 10,
			},
			search: '1',
			tableData: [
				// 	{
				// 	agencyName: '浙江省海运集团船务',
				// 	businessNum: '2021062310273384',
				// 	businessState: '已发起担保',
				// 	agencyFee: '5',
				// 	serviceCharge: '10',
				// 	totalCost: '15'
				// },
			],
			options: [{
				value: '选项1',
				label: '公司名称'
			}, {
				value: '选项2',
				label: '业务单号'
			}, {
				value: '选项3',
				label: '业务状态'
			}],
			input2: '',
			checkIndex: '4',
			userForm: {
				nickName: 'sunshine',
				userPwd: '********',
				gender: '男',
				email: '2222222222@qq.com',
				select: '1'
			},
			showSub: false,
			subscription: true,
		}
	},
	methods: {
		initData(formInline) {
			this.userForm = {
				phone: this.vuex_user.phone,
				nickName: this.vuex_user.nickName,
				gender: this.vuex_user.gender,
				email: this.vuex_user.email,
				subscribe: this.vuex_user.subscribe
			}
			this.$u.api.agencyMemberOrder(this.formInline).then(res => {
				this.total = res.data.totalElements
				this.tableData = res.data.content
				console.log(res.data)
			})
			console.log(this.formInline.companyName);
		},
		checkIfboat(id, boat) {
			if (boat == 1) {
				this.$u.api.agencyOrderFeedback({ ifBoat: boat, orderId: id }).then(res => {
					this.uni.showToast('确认已上船')
					this.initData()
					console.log(res);
				})
			} else {
				this.$u.api.agencyOrderFeedback({ ifBoat: boat, orderId: id }).then(res => {
					this.uni.showToast('订单已反馈')
					this.initData()
					console.log(res);
				})
			}
		},
		searchChange(e) {
			// if(e == 1) {
			// 	
			// 	console.log(this.input2);

			console.log(e);
		},
		inputChange(e) {
			if (this.search == 1) {
				this.formInline.companyName = e
			} else if (this.search == 2) {
				this.formInline.orderNum = e
			} else if (this.search == 3) {
				this.formInline.status = e
			}
			this.total = 1
			this.formInline.page = 1
			this.initData(this.formInline)
			console.log(this.formInline);
			console.log(e);
		},
		handleEdit(index, row) {
			this.jump('pc_guarantee_detail?id=' + row.id)
			console.log(index, row);
		},
		handleDelete(index, row) {
			console.log(row);
			this.uni.showModal({
				title: "温馨提示",
				content: "是否取消该订单?",
				success: (res) => {
					if (res.confirm) {
						this.$u.api.agencyOrderCancel(row.id + '').then(res => {
							this.uni.showToast('取消订单成功');
							console.log(res);
							setTimeout(() => {
								this.winBack()
							}, 400);
						})

					}
				},
			});

			// row.splice(index, 1);
			console.log(index, row);
			// console.log(this.tableData);
		},
		editSub() {
			if (this.subscription) {
				this.userForm.subscribe = true
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅开启成功');
						this.showSub = false
					})
				})
			} else {
				this.userForm.subscribe = false
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅关闭成功');
						this.showSub = false
					})
				})
			}

		},
		handleSizeChange(val) {
			this.formInline.size = val
			this.initData(this.formInline)
		},
		handleCurrentChange(val) {
			this.formInline.page = val - 1
			this.initData(this.formInline)
		},
		check(index) {
			this.checkIndex = index
			if (index == '1') {
				this.jump('account_manage')
			}
			else if (index == '2') {
				this.jump('buy_package')
			}
			else if (index == '3-1') {
				this.jump('resume_manage')
			}
			else if (index == '3-2') {
				this.jump('delivery_record')
			}
			else if (index == '3-3') {
				this.jump('collection_position')
			}
			else if (index == '5') {
				this.jump('training_order')
			}
			else if (index == '6') {
				this.jump('document_handling')
			}
			else if (index == '7') {
				this.showSub = true
			}
			console.log(this.checkIndex)
		},
	}
}
</script>
<style>
page {
	background: none;
}

.el-submenu .el-menu-item {
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.personal {
	margin: 0  auto; width:68.5vw; /* 边距 */
	padding-top: 30px;
	background: #fff;
	box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);

	.menuContent {
		background: #fff;
		padding: 0.5vw 2.083vw 1.5625vw;

		.personal-agent {
			.agent-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.agent-title {
					font-size: 1.0416vw;
				}

				.agent-btn {
					text-align: center;
					width: 120px;
					height: 40px;
					line-height: 40px;
					border-radius: 10px;
					border: 1px solid #ccc;
					background-color: #2089ff;
					color: #fff;
					cursor: pointer;
				}
			}

			.search {
				margin-top: 2.604vw;
				text-align: right;

				.el-select {
					width: 5.729vw;
				}

				.el-input-group {
					width: 300px;

				}

				// .el-input {
				// 	width: 9.8958vw;
				// }
			}

			.el-table {
				margin-top: 2.604vw;
			}
		}
	}
}
</style>
