<template>
	<div>
		<div class="enterprise">
			<div style="background-color: #fff;">
				<el-row>
					<el-col :span="4">
						<el-menu default-active="9" @select="check" class="el-menu-vertical-demo" @click="check">
							<el-menu-item index="1">
								<span slot="title">首页</span>
							</el-menu-item>
							<el-menu-item index="2">
								<span slot="title">账号中心</span>
							</el-menu-item>
							<el-menu-item index="3">
								<span slot="title">套餐购买</span>
							</el-menu-item>
							<el-submenu index="4">
								<template slot="title">
									<span>企业招聘</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="4-1">发布招聘信息</el-menu-item>
									<el-menu-item index="4-2">管理招聘信息</el-menu-item>
									<el-menu-item index="4-3">收到的简历投递</el-menu-item>
									<el-menu-item index="4-4">已收藏简历</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
							<!-- <el-menu-item index="5"> -->
							<!-- <template> -->
							<!-- <span slot="title">企业培训订单</span> -->
							<!-- </template>
				        <el-menu-item-group>
				          <el-menu-item index="5-1">培训信息</el-menu-item>
				          <el-menu-item index="5-2">培训</el-menu-item> -->
							<!-- <el-menu-item index="5-3">培训学校</el-menu-item> -->
							<!-- </el-menu-item-group> -->
							<!-- </el-menu-item> -->
							<!-- <el-menu-item index="6">
				          <span slot="title">企业办证订单</span>
				      </el-menu-item> -->
							<!-- <el-submenu index="6">
							  <template slot="title">
							    <span>企业办证</span>
							  </template>
							  <el-menu-item-group>
							    <el-menu-item index="6-1">办证信息</el-menu-item>
							    <el-menu-item index="6-2">办证订单</el-menu-item>
							  </el-menu-item-group>
							</el-submenu> -->
							<!-- <el-menu-item index="7">
				        <span slot="title">企业公告</span>
				      </el-menu-item> -->
							<el-menu-item index="8">
								<span slot="title">中介担保</span>
							</el-menu-item>
							<!-- <el-menu-item index="9">
							  <span slot="title">企业资料</span>
							</el-menu-item> -->
							<el-menu-item index="10">
								<span slot="title">我的订阅</span>
							</el-menu-item>
						</el-menu>
					</el-col>
					<el-col :span="20">
						<div class="menuContent">
							<div v-if="tableData.length == 0" class="noPulished">
								<div>您的企业还没有发布资料</div>
								<el-button type="primary" @click="jump('ec_upload_data')">上传资料</el-button>
							</div>
							<div v-else>
								<div class="trainInfo">
									<div class="trainInfo-title">发布资料</div>
									<el-button type="default" @click="jump('ec_upload_data')">上传资料</el-button>
								</div>
								<el-form ref="form" :model="formInline" label-width="100px">
									<el-form-item label="发布时间：">
										<el-button type="text" @click="checkStatus">不限</el-button>
										<el-col :span="11">
											<el-date-picker v-model="formInline.date" type="date" placeholder="选择日期">
											</el-date-picker>
										</el-col>
									</el-form-item>
									<el-form-item label="发布状态：">
										<el-button type="text" @click="checkStatus">不限</el-button>
										<el-button type="text">已发布</el-button>
										<el-button type="text">已隐藏</el-button>
									</el-form-item>
									<el-form-item label="资料类别：">
										<el-select v-model="formInline.date" placeholder="请选择">
											<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
											</el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="资料搜索：" class="enterprise-search">
										<el-input placeholder="输入资料名称" v-model="formInline.search" class="input-with-select">

										</el-input>
										<el-button ref="searchBtn" :type="formInline.search ? 'primary' : 'info'">搜索</el-button>
									</el-form-item>
								</el-form>
								<el-table border :data="tableData" style="width: 100%" :header-cell-style="{
									'background-color': 'rgba(32, 137, 255, 0.07)',
									color: '#333',
									'font-size': '0.833vw',
									'font-weight': '400'
								}">
									<el-table-column type="selection" width="55">
									</el-table-column>
									<el-table-column prop="dataCategories" label="资料类别" width="100"></el-table-column>
									<el-table-column prop="dataName" label="资料名称"></el-table-column>
									<el-table-column prop="size" label="大小" width="80"></el-table-column>
									<el-table-column prop="status" label="状态" width="80"></el-table-column>
									<el-table-column prop="uploadTime" label="上传时间"></el-table-column>
									<el-table-column label="操作" width="100">
										<template slot-scope="scope">
											<el-button type="text" @click="handleEdit(scope.$index, scope.row)">隐藏</el-button>
											<el-button type="text" @click="handleEdit(scope.$index, scope.row)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
								<div class="tableBottom">
									<!-- <div>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量发布</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量隐藏</el-button>
				<el-button type="text" @click="handleEdit(scope.$index, scope.row)">批量删除</el-button>
			</div> -->
									<div style="text-align:right;">
										<el-pagination style="margin-top:20px;text-align:right;" :current-page="currentPage"
											:page-sizes="[10, 20, 50, 100]" :page-size="10" layout="prev, pager, next, jumper" :total="total"
											@size-change="handleSizeChange" @current-change="handleCurrentChange" />
									</div>
								</div>
							</div>
						</div>
					</el-col>

				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			checkIndex: '9',
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}
				]
			},
			form: {
				date: '',
				employJob: '选项1',
				search: ''
			},
			options: [
				{
					value: '选项1',
					label: '全部类别'
				},
				{
					value: '选项2',
					label: '机工'
				}
			],
			tableData: [
				{
					dataCategories: '船员培训',
					dataName: '船员航线培训考试内部资料.rar',
					size: '3.24M',
					status: '已发布',
					uploadTime: '2021-06-20 12:00:00',
				},
				{
					dataCategories: '船员培训',
					dataName: '船员航线培训考试内部资料.rar',
					size: '3.24M',
					status: '已发布',
					uploadTime: '2021-06-20 12:00:00',
				},
				{
					dataCategories: '船员培训',
					dataName: '船员航线培训考试内部资料.rar',
					size: '3.24M',
					status: '已发布',
					uploadTime: '2021-06-20 12:00:00',
				},
				{
					dataCategories: '船员培训',
					dataName: '船员航线培训考试内部资料.rar',
					size: '3.24M',
					status: '已发布',
					uploadTime: '2021-06-20 12:00:00',
				},
				{
					dataCategories: '船员培训',
					dataName: '船员航线培训考试内部资料.rar',
					size: '3.24M',
					status: '已发布',
					uploadTime: '2021-06-20 12:00:00',
				}
			],
			currentPage: 1,
			total: 0,
			formInline: {
				createTime: "", // 搜索字段
				job: '',
				isTop: "",
				isOpen: '',
				page: 0, // 当前页
				size: 10,
			},
			select: '1'
		}
	},
	methods: {
		initData() {
		},
		onSubmit() {
			console.log('submit!');
		},

		handleEdit(index, row) {
		},
		checkStatus(e) {
		},
		handleSizeChange(val) {
			this.formInline.size = val
			this.initData(this.formInline)
		},
		handleCurrentChange(val) {
			this.formInline.page = val - 1
			this.initData(this.formInline)
		},
		check(index) {
			this.checkIndex = index
			if (index == '1') {
				this.jump('enterprise_index')
			} else if (index == '2') {
				this.jump('ec_account_manage')
			}
			else if (index == '3') {
				this.jump('ec_buy_package')
			}
			else if (index == '4-1') {
				this.jump('post_recruitment_info')
			}
			else if (index == '4-2') {
				this.jump('manage_recruitment_info')
			}
			else if (index == '4-3') {
				this.jump('resume_delivery_received')
			}
			else if (index == '4-4') {
				this.jump('collected_resume')
			}
			else if (index == '5-1') {
				this.jump('ec_training_info')
			}
			else if (index == '5') {
				this.jump('ec_training_order')
			}
			else if (index == '7') {
				this.jump('corporate_announcements')
			}
			else if (index == '8') {
				if (this.vuex_user.openAgency) {
					this.jump('ec_mediation_guarantee')
				} else {
					this.uni.showToast({ title: '您未开通中介担保', icon: 'none' })
				}
			}
			else if (index == '9') {
				this.jump('published_data')
			}
			else if (index == '10') {
				this.jump('ec_my_subscription')
			}
		},
	}
}
</script>
<style>
page {
	background: #fafafa;
}

.enterprise-search>>>.el-form-item__content {
	display: flex;
}
</style>
<style lang="less" scoped>
.enterprise {
	margin: 10px 18.75vw 6.25vw;

	.menuContent {
		background: #fff;
		padding: 1.5625vw 2.083vw;

		.noPulished {
			// position: absolute;
			padding: 11.979vw;
			text-align: center;

			// left: 360px;
			div {
				font-size: 0.833vw;
			}

			button {
				font-size: 0.833vw;
				margin-top: 3.6458vw;
				width: 6.25vw;
			}
		}

		.trainInfo {
			// width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e5e5e5;
			padding-bottom: 20px;

			.trainInfo-title {
				font-size: 1.0416vw;
			}

			button {
				margin: 0;
				width: 100px;
				background-color: #2089ff;
				font-size: 14px;
				color: #ffffff;
			}
		}

		.el-form {
			margin-top: 2.604vw;

			.el-input {
				width: 15.625vw;
				margin-right: 0.52vw;
			}
		}

		.el-table {
			margin-top: 2.083vw;
		}

		.tableBottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.833vw 0 6.25vw;
		}
	}
}
</style>
