<template>
	<div class="personal">
		<div style="background-color: #fff;">
			<el-row>
				<el-col :span="4">
					<el-menu default-active="1" @select="check" class="el-menu-vertical-demo" @click="check">
						<el-menu-item index="1">
							<span slot="title">账号管理</span>
						</el-menu-item>
						<el-menu-item index="2">
							<span slot="title">套餐购买</span>
						</el-menu-item>
						<el-submenu index="3">
							<template slot="title">
								<span>我的简历</span>
							</template>
							<el-menu-item-group>
								<el-menu-item index="3-1">简历管理</el-menu-item>
								<el-menu-item index="3-2">投递记录</el-menu-item>
								<el-menu-item index="3-3">收藏企业</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item index="4">
							<span slot="title">中介担保</span>
						</el-menu-item>
						<el-menu-item index="5">
							<span slot="title">培训订单</span>
						</el-menu-item>
						<el-menu-item index="6">
							<span slot="title">证件办理</span>
						</el-menu-item>
						<el-menu-item index="7">
							<span slot="title">我的订阅</span>
						</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="20">
					<div class="menuContent">
						<div class="manage">
							<div class="manage-title">基本信息</div>
							<el-form ref="form" :model="userForm" label-position="left" label-width="5.5vw">
								<el-form-item label="账号:">
									<el-input disabled v-model="userForm.phone"></el-input>
								</el-form-item>
								<el-form-item label="昵称:">
									<el-input readonly v-model="userForm.nickName"></el-input>
								</el-form-item>
								<!-- <el-form-item label="登录密码:">
		    <el-input v-model="userForm.userPwd" show-password></el-input>
		  </el-form-item> -->
								<el-form-item label="用户性别:">
									<el-input readonly v-model="userForm.gender"></el-input>
								</el-form-item>
								<el-form-item label="电子邮箱:">
									<el-input readonly v-model="userForm.email"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click.stop="jump('pc_change_info')">修改</el-button>
									<el-button @click.stop="showEdit = true, editForm = {}">修改密码</el-button>
								</el-form-item>
							</el-form>
							<el-dialog title="修改密码" :visible.sync="showEdit" width="30%">
								<el-form ref="form" :model="editForm" label-position="left" label-width="5.5vw">
									<el-form-item label="旧密码:">
										<el-input v-model="editForm.oldPassword" show-password></el-input>
									</el-form-item>
									<el-form-item label="新密码:">
										<el-input v-model="editForm.newPassword" show-password></el-input>
									</el-form-item>
									<el-form-item label="确认密码:">
										<el-input v-model="editForm.newPassword2" show-password></el-input>
									</el-form-item>
									<el-form-item>
										<el-button @click="showEdit = false">取 消</el-button>
										<el-button type="primary" @click.stop="changePwd">修改</el-button>
									</el-form-item>
								</el-form>
							</el-dialog>
						</div>
					</div>
					<el-dialog title="提示" :visible.sync="showSub" width="30%">
						<span v-if="userForm.subscribe" style="margin-right:20px">是否关闭订阅</span>
						<span v-else style="margin-right:20px">是否开启订阅</span>
						<el-radio v-model="subscription" :label="true">是</el-radio>
						<el-radio v-model="subscription" :label="false">否</el-radio>
						<span slot="footer" class="dialog-footer">
							<el-button @click="showSub = false">取 消</el-button>
							<el-button type="primary" @click="editSub">确 定</el-button>
						</span>
					</el-dialog>
				</el-col>

			</el-row>
		</div>

	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			checkIndex: '1',
			userForm: {
				userName: 'sunshine',
				userPwd: '********',
				userSex: '男',
				userEmail: '2222222222@qq.com'
			},
			showSub: false,
			subscription: true,
			editForm: {},
			showEdit: false
		}
	},
	methods: {
		initData() {
			this.$u.api.getInfo().then(res => {
				console.log(res);
			})
			this.userForm = {
				phone: this.vuex_user.phone,
				nickName: this.vuex_user.nickName,
				gender: this.vuex_user.gender,
				email: this.vuex_user.email,
				subscribe: this.vuex_user.subscribe
			}
			console.log(this.vuex_user)
		},
		changePwd() {
			if (!this.editForm.oldPassword) {
				this.uni.showToast({
					title: '请输入旧密码',
					icon: 'none'
				});
				return
			}
			if (!this.editForm.newPassword) {
				this.uni.showToast({
					title: '请输入新密码',
					icon: 'none'
				});
				return
			}
			if (this.editForm.newPassword.length < 6) {
				this.uni.showToast({
					title: '密码长度不可少于6',
					icon: 'none'
				});
				return
			}
			// if(!this.editForm.newPassword2) {
			// 	this.uni.showToast({
			// 		title: '请再次输入新密码',
			// 		icon: 'none'
			// 	});
			// 	return
			// }
			// if(this.editForm.newPassword2.length < 6) {
			// 	this.uni.showToast({
			// 		title: '密码长度不可少于6',
			// 		icon: 'none'
			// 	});
			// 	return
			// }
			if (this.editForm.newPassword2 !== this.editForm.newPassword) {
				this.uni.showToast({
					title: '密码不一致',
					icon: 'none'
				});
				return
			}
			this.$u.api.memberUpdatePassword(this.editForm).then(res => {
				this.uni.showToast({
					title: '密码修改成功',
					icon: 'none'
				});
				this.showEdit = false
				console.log(res);
			})
		},
		onSubmit() {
			console.log('submit!');
		},
		editSub() {
			if (this.subscription) {
				this.userForm.subscribe = true
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅开启成功')
						this.showSub = false
					})
				})
			} else {
				this.userForm.subscribe = false
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅关闭成功')
						this.showSub = false
					})
				})
			}

		},
		check(index) {
			this.checkIndex = index
			if (index == '2') {
				this.jump('buy_package')
			}
			else if (index == '3-1') {
				this.jump('resume_manage')
			}
			else if (index == '3-2') {
				this.jump('delivery_record')
			}
			else if (index == '3-3') {
				this.jump('collection_position')
			}
			else if (index == '4') {
				this.jump('personal_guarantee')
			}
			else if (index == '5') {
				this.jump('training_order')
			}
			else if (index == '6') {
				this.jump('document_handling')
			}
			else if (index == '7') {
				// this.jump('my_subscription')
				this.showSub = true
			}
			console.log(this.checkIndex)
		},
	}
}
</script>
<style>
page {
	background: none;
}

.el-submenu .el-menu-item {
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.personal {
	// margin: 0 15.25vw 6.25vw;
	margin: 0  auto; width:68.5vw; /* 边距 */
	box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
	padding-top: 30px;
	background: #fff;

	.menuContent {
		background: #fff;
		padding: 1.5625vw 2.083vw;

		.manage {

			.manage-title {
				font-size: 1.04167vw;
				font-weight: 500;
			}

			.el-form {
				margin-top: 2.604vw;

				.el-form-item {
					margin-bottom: 1.5625vw;

					.el-input {
						width: 20.83vw;
					}

					.el-select {
						width: 20.83vw;
					}

					.el-button {
						margin-top: 2.083vw;
						width: 6.25vw;
					}
				}
			}
		}
	}
}
</style>