<template>
	<div class="personal">
		<div style="background-color: #fff;">
			<el-row>
				<el-col :span="4">
					<el-menu default-active="6" @select="check" class="el-menu-vertical-demo" @click="check">
						<el-menu-item index="1">
							<span slot="title">账号管理</span>
						</el-menu-item>
						<el-menu-item index="2">
							<span slot="title">套餐购买</span>
						</el-menu-item>
						<el-submenu index="3">
							<template slot="title">
								<span>我的简历</span>
							</template>
							<el-menu-item-group>
								<el-menu-item index="3-1">简历管理</el-menu-item>
								<el-menu-item index="3-2">投递记录</el-menu-item>
								<el-menu-item index="3-3">收藏企业</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item index="4">
							<span slot="title">中介担保</span>
						</el-menu-item>
						<el-menu-item index="5">
							<span slot="title">培训订单</span>
						</el-menu-item>
						<el-menu-item index="6">
							<span slot="title">证件办理</span>
						</el-menu-item>
						<el-menu-item index="7">
							<span slot="title">我的订阅</span>
						</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="20">
					<div class="menuContent">
						<div class="documenthand">
							<div class="documenthand-title">证件办理</div>
							<div class="search">
								<!-- <el-input placeholder="搜索" v-model="input2" suffix-icon="el-icon-search" class="input-with-select" @change="inputChange">
			    <el-select v-model="search" slot="prepend" placeholder="请选择" @change="searchChange">
			      <el-option label="业务单号" value="1"></el-option>
			      <el-option label="" value="2"></el-option> -->
								<!-- <el-option label="业务状态" value="3"></el-option> -->
								<!-- </el-select>
			  </el-input> -->
							</div>
							<el-table :data="tableData" border style="width: 100%" :header-cell-style="{
								'background-color': 'rgba(32, 137, 255, 0.07)',
								'color': '#333',
								'font-size': '0.833vw',
								'font-weight': '400'
							}">
								<el-table-column prop="papers.name" label="证件名称"></el-table-column>
								<el-table-column prop="name" label="申办人" width="80"></el-table-column>
								<el-table-column prop="orderNum" label="业务单号"></el-table-column>
								<el-table-column prop="status" label="业务状态" width="100">
									<template scope="scope">
										<p v-if="scope.row.status == 1">待支付</p>
										<p v-if="scope.row.status == 2">已过期</p>
										<p v-if="scope.row.status == 3">办理中</p>
										<p v-if="scope.row.status == 4">申请退款</p>
										<p v-if="scope.row.status == 5">已退款</p>
										<p v-if="scope.row.status == 6">完结</p>
									</template>
								</el-table-column>
								<el-table-column prop="pay" label="办理费用" width="100"></el-table-column>
								<el-table-column label="操作" width="80">
									<template slot-scope="scope">
										<el-button type="text" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination style="text-align:right;margin-top:20px" :current-page="currentPage"
								:page-sizes="[10, 20, 50, 100]" :page-size="10" layout="prev, pager, next, jumper" :total="total"
								@size-change="handleSizeChange" @current-change="handleCurrentChange">
							</el-pagination>
						</div>
					</div>
					<el-dialog title="提示" :visible.sync="showSub" width="30%">
						<span v-if="userForm.subscribe" style="margin-right:20px">是否关闭订阅</span>
						<span v-else style="margin-right:20px">是否开启订阅</span>
						<el-radio v-model="subscription" :label="true">是</el-radio>
						<el-radio v-model="subscription" :label="false">否</el-radio>
						<span slot="footer" class="dialog-footer">
							<el-button @click="showSub = false">取 消</el-button>
							<el-button type="primary" @click="editSub">确 定</el-button>
						</span>
					</el-dialog>
				</el-col>

			</el-row>
		</div>

	</div>
</template>

<script>
import BaseMixin from '@/mixins/BaseMixin.vue'
export default {
	mixins: [BaseMixin],
	data() {
		return {
			currentPage: 0,
			total: 1,
			formInline: {
				page: 0, // 当前页
				size: 10,
			},
			value: '1',
			tableData: [
				// 	{
				// 	certificateName: '不值班适任证书+服务簿新注册',
				// 	declarant: '王也',
				// 	businessNum: '2021062310273384846',
				// 	businessState: '办理完成',
				// 	biddingCost: '400'
				// }
			],
			options: [{
				value: '选项1',
				label: '证件名称'
			}, {
				value: '选项2',
				label: '业务单号'
			}, {
				value: '选项3',
				label: '业务状态'
			}],
			name: '',
			checkIndex: '6',
			userForm: {
				nickName: 'sunshine',
				userPwd: '********',
				gender: '男',
				email: '2222222222@qq.com',
				select: '1'
			},
			showSub: false,
			subscription: true,
		}
	},
	methods: {
		initData(formInline) {
			this.userForm = {
				phone: this.vuex_user.phone,
				nickName: this.vuex_user.nickName,
				gender: this.vuex_user.gender,
				email: this.vuex_user.email,
				subscribe: this.vuex_user.subscribe
			}
			this.$u.api.paperOderList(formInline).then(res => {
				this.total = res.data.totalElements
				this.tableData = res.data.content
				console.log(res)
			})

		},
		handleEdit(index, row) {
			this.jump('document_handling_detail?id=' + row.id)
			console.log(index, row);
		},
		editSub() {
			if (this.subscription) {
				this.userForm.subscribe = true
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅开启成功')
						this.showSub = false
					})
				})
			} else {
				this.userForm.subscribe = false
				this.$u.api.updateInfo(this.userForm).then(res => {
					this.$u.api.getInfo().then(resp => {
						this.$u.vuex('vuex_user', resp.data)
						this.uni.showToast('订阅关闭成功')
						this.showSub = false
					})
				})
			}

		},
		handleSizeChange(val) {
			this.formInline.size = val
			this.initData(this.formInline)
		},
		handleCurrentChange(val) {
			this.formInline.page = val - 1
			this.initData(this.formInline)
		},
		check(index) {
			this.checkIndex = index
			if (index == '1') {
				this.jump('account_manage')
			}
			else if (index == '2') {
				this.jump('buy_package')
			}
			else if (index == '3-1') {
				this.jump('resume_manage')
			}
			else if (index == '3-2') {
				this.jump('delivery_record')
			}
			else if (index == '3-3') {
				this.jump('collection_position')
			}
			else if (index == '4') {
				this.jump('personal_guarantee')
			}
			else if (index == '5') {
				this.jump('training_order')
			}
			else if (index == '7') {
				this.showSub = true
			}
			console.log(this.checkIndex)
		},
		search() {
			if (this.name === '') {
				return
			}
			this.searchForm.name = this.name
			this.initList()
		},
	},
}
</script>
<style>
page {
	background: none;
}

.el-input>>>.el-input-group__prepend {
	background-color: #fff;
}

.el-submenu .el-menu-item {
	min-width: 100%;
}
</style>
<style lang="less" scoped>
.personal {
	margin: 0  auto; width:68.5vw; /* 边距 */
	padding-top: 30px;
	box-shadow: 0px 8px13px0px rgba(6, 27, 51, 0.13);
	background: #fff;

	.menuContent {
		background: #fff;
		padding: 0.5vw 2.083vw 1.5625vw;

		.documenthand {
			.documenthand-title {
				font-size: 1.0416vw;
			}

			.search {
				margin-top: 2.604vw;
				text-align: right;

				.el-input-group {
					width: 300px;

				}

				.el-select {
					width: 5.729vw;
					// border: 1px solid #E6E6E6;
					// background-color: #fff;
				}

			}

			.el-table {
				margin-top: 0.78vw;
			}
		}
	}
}
</style>
